<template>
  <div class="home_bottom">
    <div class="bg">
      <div class="title">
        <!-- <p>Some Promising reasons to choose</p> -->
        <h3>iCredito</h3>
      </div>
      <ul class="list">
        <li class="left_item">
          <img src="@/assets/images/loanImg/icon_affordable.png" alt="">
          <p class="text1">
            Multiples prestamistas ofrecen<br>
            multiples productos de prestamos.
          </p>
          <p class="text2">
            iCredito proporciona múltiples prestamistas que brindan<br>
            múltiples productos de préstamos para satisfacer sus<br>
            necesidades de préstamos de emergencia.
          </p>
        </li>
        <li class="right_item">
          <img src="@/assets/images/loanImg/icon_easy.png" alt="">
          <p class="text1">Proporciona informe de crédito gratuito</p>
          <p class="text2">
            Esta aplicación lo ayuda a potenciar al instante decisiones<br>
            para beneficiar su credito en:bancos, billeteras y comercio<br>
            electrónico,nuestras alianzas con el crédito empresas de<br>
            información Experian y CIBIL, respectivamente, para<br>
            ofrecer informes crediticios gratuitos,esto hace que sus<br>
            préstamos sean más eficientes.
            <!-- SmartCreditScore powers instant<br>
            decisions for NBFCs, Banks, Wallets and E-Commerce,<br>our partnerships with
            credit<br>
            information companies Experian and CIBIL, <br>respectively, to offer free credit
            reports, <br>made your lending more efficient. -->
          </p>
        </li>
        <li class="left_item last">
          <img src="@/assets/images/loanImg/icon_we.png" alt="">
          <p class="text1">Somos Rapidos</p>
          <p class="text2">
            Cuando se genera el informe de crédito, el préstamo<br>
            se recibirá muy pronto en menos de 1 hora después<br>
            de su solicitud.
          </p>
        </li>
        <li class="right_item last">
          <img src="@/assets/images/loanImg/icon_me.png" alt="">
          <p class="text1">Siempre para tí</p>
          <p class="text2">
            Nuestro equipo de trabajo esta en línea las 72 horas,<br>
            para así brindar el mejor servicio y atención a sus necesidades.
          </p>
        </li>
        <li class="left_item last">
          <img src="@/assets/images/loanImg/icon-simple.png" alt="">
          <p class="text1">Procedimiento sencillo</p>
          <p class="text2">
            Reducimos el requerimiento de documentación<br>
            para brindarle la forma más fácil de obtener el<br>
            préstamo. Solo necesitas completar tu solicitud,<br>
            sin garantías ni avales
          </p>
        </li>
        <li class="right_item last">
          <img src="@/assets/images/loanImg/icon_bank.png" alt="">
          <p class="text1">El crédito aumentará el monto<br> de su préstamo</p>
          <p class="text2">
            El pago a tiempo ayuda a mejorar el crédito. Usted<br>
            podrá aumentar el límite de su préstamo en función del crédito.
          </p>
        </li>
      </ul>
      <div class="phome">
        <img src="@/assets/images/loanImg/phone.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.home_bottom {
  min-width: 1200px;
  .bg {
    position: relative;
    background: url("~@/assets/images/loanImg/bn.png") no-repeat center bottom;
    .phome {
      position: absolute;
      top: 250px;
      left: 50%;
      transform: translate(-50%);
    }
  }
  .title {
    text-align: center;
    margin-bottom: 90px;
    p {
      color: #333333;
      font-size: 16px;
      margin: 0;
    }
    h3 {
      font-size: 68px;
      color: #F7A942;
    }
  }
  .list {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    padding: 0 20px;
    li {
      width: 580px;
      float: left;
      height: 245px;
      margin-bottom: 46px;
      img {
        width: 30px;
        height: 30px;
        margin-bottom: 20px;
      }
      .text1 {
        font-size: 22px;
        color: #333333;
        font-weight: 700;
        line-height: 30px;
      }
      .text2 {
        font-size: 13px;
        color: #666666;
        line-height: 19px;
      }
      &.left_item {
        text-align: left;
      }
      &.right_item {
        text-align: right;
      }
      &.last {
        .text1,
        .text2 {
          color: #ffffff;
        }
      }
    }
  }
}
</style>
