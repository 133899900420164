<template>
  <div class="hotel-home">
    <div class="con_nav">
      <nav-tab :nav-data="navData" />
    </div>
    <div class="con_center">
      <HomeTop />
      <HomeBottom />
    </div>
    <div class="con_bottom">
      <layout-bottom />
    </div>
  </div>
</template>

<script>
import NavTab from '@/components/NavTab'
import LayoutBottom from '@/components/LayoutBottom'
import HomeTop from '@/components/home/homeTop'
import HomeBottom from '@/components/home/homeBottom'
export default {
  components: {
    NavTab,
    HomeTop,
    HomeBottom,
    LayoutBottom
  },
  data() {
    return {
      navData: {
        index: 0,
        list: [
          {
            name: 'inicio',
            link: '/'
          },
          {
            name: 'Pasos para pedir prestado',
            link: '/how_it_works'
          },
          {
            name: 'Preguntas frecuentes',
            link: '/faq'
          },
          {
            name: 'Contactar con nosotros',
            link: '/contact_us'
          }]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
// .con_center{
//     background: url("~@/assets/images/loanImg/bn_ee.png") no-repeat top left;

// }
</style>
